import * as React from 'react'
import { Link } from 'react-router-dom'
import '../Nav.css'

function Navbar() {
  return (
    <div className='navbar'>
        <ul className='navbar-menu'>
            <li><Link className="nav-link" to="/">Home</Link></li>
            <li><Link className="nav-link" to="/projects">Projects</Link></li>
            <li><a className="nav-link" href="https://x.com/ExtincTay">Twitter</a></li>
        </ul>
    </div>
  )
}

export default Navbar
