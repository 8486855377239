import React from 'react'
import Navbar from './components/NavBar'

function App() {
  return (
    <div>
        <Navbar/>
        <div style={{  textAlign: "left", marginTop: "15%", marginLeft: "10%",  }}>
            <h1>Hey, im <u style={{  color: "var(--accent)"  }}>Tay</u></h1>
            <h2>i dont know what to put here</h2>
            <h2>im currently working on a game but thats a secret so shhh</h2>
            <h2>might get back into making cool projects soon</h2>
        </div>
        <div style={{  float: "right", translate: "-65% -65%", }}>
            <img style={{  borderRadius: "25px", border: "var(--secondary) 5px solid" }} alt="" src="https://pbs.twimg.com/profile_images/1803439157089300480/twTYJm1I_400x400.jpg"/>
        </div>
    </div>
  )
}

export default App
